import './Header.css'

const Header = () => {
    return (
        <header id='header'>
            <div className='main'>
                <a href="https://yuvalronmusic.com/" target='_blank' rel="noreferrer">
                    <img className='profile' src='/yuval-profile.jpeg'/>
                </a>
                {/* <img src='/yuval.png'/> */}
            </div>
        </header>
    )
}

export default Header