import Header from './Header'
import NFTSlides from './NFTSlides'

const Collection = ({collection, tokens}) => {
    const collectionStyles = styles(collection)
    console.log(collection)
    return (
        <div style={collectionStyles.collection}>
            <Header />
            {tokens && <NFTSlides tokens={tokens} />}
            <div className='overlay'></div>
        </div>
    )
}

const styles = (collection) => ({
    collection: {
        width: '100vw',
        height: 'fit-content',
        backgroundImage: `url(${collection ? '/yuval.JPG' : '/placeholder.jpeg'})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '2em 0',
        position: 'relative',
    }
})

export default Collection