import './NFTCard.css'

const NFTCard = ({ token }) => {
    console.log(token)
    const name = token ? token.name.split('By')[0]: 'None'
    return (
        <div className='card' style={{backgroundImage: `url(${token.image_url})`, margin: '1.5em auto'}}>
            {/* <img style={{maxWidth: '250px'}} src={token.image_url} /> */}
            <div className='main' style={{marginLeft: '1em'}}>
                <h4 style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{name}</h4>
                {/* <p style={{overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '1em'}}>{token.description}</p> */}
                {/* <a href={token.permalink}>Purchase</a> */}
            </div>
            <a className='opensea' href={token.permalink}><img src='/opensea.png' /></a>
            <a className='overlay-link' href={token.permalink}></a>
        </div>
    )
}

export default NFTCard