import logo from './logo.svg';
import './App.css';
import Shop from './components/Shop';
import { useEffect, useState } from 'react';

const COLLECTION_NAME = 'yuval'

function App() {
  const [collection, setCollection] = useState(null)
  const [tokens, setTokens] = useState(null)
  const [link, setLink] = useState(`https://api.opensea.io/api/v1/assets?collection=${COLLECTION_NAME}&limit=21`)
  const [next, setNext] = useState(null)
  const [prev, setPrev] = useState(null)
  
  useEffect(() => {
      fetch(`https://api.opensea.io/api/v1/collection/${COLLECTION_NAME}`)
      .then(res => res.json())
      .then(({ collection }) => setCollection(collection))
      .catch((e) => console.log(e))
  }, [])

  useEffect(() => {
      fetch(link)
          .then(res => res.json())
          .then((data) => {
              const { assets, next, previous } = data
              setTokens(assets)
              setNext(next ? `${link}&cursor=${next}` : undefined)
              setPrev(previous ? `${link}&cursor=${previous}` : undefined)
          })
          .catch((e) => console.log(e))
  }, [])

  return (
    <Shop  collection={collection} tokenState={[tokens, setTokens]} nextState={[next, setNext]} prevState={[prev, setPrev]} link={link} />
  );
}

export default App;
