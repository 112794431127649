import { useState } from "react"
import Collection from "./Collection"
import NFTSection from "./NFTSection"

const Shop = ({ collection, tokenState, nextState, prevState, link }) => {

    return (
        <div style={{paddingBottom: '2em'}}>
            <Collection collection={collection} tokens={tokenState[0]} />
            <h1 style={{textAlign: 'center', fontWeight: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1em'}}>Metta Mindfulness Music</h1>
            <p style={{textAlign: 'center'}}>NFT music collection</p>
            <NFTSection collection={collection} link={link} tokenState={tokenState} nextState={nextState} prevState={prevState} />
            <a href='https://mettamindfulnessmusic.com/' target='_blank' rel="noreferrer">
                <h1 style={{textAlign: 'center', fontWeight: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0}}><img src='/metamindfulness.png' /></h1>
            </a>
        </div>
    );
}

export default Shop