import './NFTSection.css'
import { useState } from 'react'
import NFTCard from './NFTCard'

const NFTSection = ({ 
    collection,
    link,
    tokenState,
    nextState,
    prevState,
}) => {
    const contractAddress = '0x495f947276749ce646f68ac8c248420045cb7b5e'
    const [tokens, setTokens] = tokenState
    const [next, setNext] = nextState
    const [prev, setPrev] = prevState
    const [numberViewed, setNumberViewed] = useState(21)

    const paginate = (newLink, isPrev) => {
        console.log('****', newLink)
        console.log('****', tokens)
        fetch(newLink)
        .then(res => res.json())
        .then(({ assets, next, previous }) => {
            setTokens(assets)
            console.log(tokens)
            setNext(next ? `${link}&cursor=${next}` : undefined)
            setPrev(previous ? `${link}&cursor=${previous}` : undefined)
            console.log('******', next, previous)
            setNumberViewed(isPrev ? numberViewed - assets.length : numberViewed + assets.length)
        })
    }

    return (
        <>
            <div className='cardContainer'>
                {
                    tokens && tokens.map((token) => 
                    <NFTCard token={token} />
                    )
                }

            </div>
            <div style={{width: 'fit-content', margin: '2em auto'}}>
                {/* {collection && <p className='number-viewed'>{`${numberViewed}/${collection.stats.count}`}</p>} */}
                {prev ? <button onClick={() => paginate(prev, true)} className='page-control'>Previous Page</button> : <button onClick={() => paginate(prev, true)} className='page-control' disabled>Previous Page</button>}
                {next ? <button  onClick={() => paginate(next)} className='page-control'>Next Page</button> : <button  onClick={() => paginate(next)} className='page-control' disabled>Next Page</button>}
            </div>
        </>
    )
}

export default NFTSection