import { useEffect, useState } from "react"
import NFTCard from "./NFTCard"

const NFTSlides = ({tokens}) => {
    const [index, setIndex] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            if(index !== tokens.length-1) {
                setIndex(index+1)
            } else {
                setIndex(0)
            }
        }, 2000)
    })
    
    return (
        <div style={{width: '80vw', margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: "center"}}>
            <NFTCard token={tokens[index]}/>
        </div>
    )
}

export default NFTSlides